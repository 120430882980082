import React, { useState, useEffect } from 'react';
import "./juegoonline.css"

const JuegoOnline = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="salas">
      <div className="image-container">
        <img
          className='imagensalas'
          src={isMobile ? './img/juegoonlinem.png' : './img/juegoonline.png'}
          alt='Salas'
        />
        <button className={`boton-bplay ${isMobile ? 'mobile' : ''}`}>
          <a href="https://www.bplay.bet.ar/" target="_blank" rel="noopener noreferrer">
            <img className='salasbtnimg' src='./img/biplay.jpg' alt='biplay' />
          </a>
        </button>
        <button className={`boton-mac ${isMobile ? 'mobile' : ''}`}>
          <a href="https://casinosmacgroup.bet.ar/" target="_blank" rel="noopener noreferrer">
            <img className='salasbtnimg' src='./img/logo-mac-top.png' alt='mac' />
          </a>
        </button>
      </div>
    </div>
  );
}

export default JuegoOnline