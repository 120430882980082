import React from 'react';

const Descargas = () => {
  return (
    <div className='text-left text-justify container fondotransparente3 p-5'>
      <center>
        <h2 className='h1 p-5 botonbordeazul text-white px-5 mx-5 py-3'>
          Descargas
        </h2>
      </center>

      <br />
      <br />

      <div className='margenazul'>
        <br />
        <br />
        <ul>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/suenos_1.jpg" download>
                Significado de los sueños pagina 1
              </a>
            </div>
            <div>
              <img
                src="./download/suenos_1.jpg"
                alt="Significado de los sueños pagina 1"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>

          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/suenos_2.jpg" download>
                Significado de los sueños pagina 2
              </a>
            </div>
            <div>
              <img
                src="./download/suenos_2.jpg"
                alt="Significado de los sueños pagina 2"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>

{/*           <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/placapremio.jpg" download>
                Placa de premio UNA MAS!
              </a>
            </div>
            <div>
              <img
                src="./download/placapremio.jpg"
                alt="Placa de premio UNA MAS!"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li> */}

{         <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/placafecha.jpg" download>
                Placa de fechas UNA MAS!
              </a>
            </div>
            <div>
              <img
                src="./download/placafecha.jpg"
                alt="Placa de fechas UNA MAS!"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li> }

          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/placaagenciavendedora.jpeg" download>
                Placa de agencia vendedora de premio UNA MAS!
              </a>
            </div>
            <div>
              <img
                src="./download/placaagenciavendedora.jpeg"
                alt="Placa de agencia vendedora de premio UNA MAS!"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/sorteo_invierno.jpg" download>
                Placa de Sorteo Lotería de Invierno
              </a>
            </div>
            <div>
              <img
                src="./download/sorteo_invierno.jpg"
                alt="Placa de Sorteo Lotería de Invierno"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>

         
        </ul>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Descargas;
